import React, { useState } from 'react';

import SnackbarContext from './SnackbarContext';
import { SnackbarContextContent } from '../../common/types';

type Props = {
  children: React.ReactNode;
};

const SnackbarContextProvider = ({ children }: Props) => {
  const [content, setContent] = useState<SnackbarContextContent>({
    open: false,
    message: '',
  });

  const value = {
    content: content,
    setContent: setContent,
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContextProvider;
