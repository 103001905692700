import React from 'react';
import { Typography, Link, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PageWrapper from '../PageWrapper';
import PrivacyForm from '../PrivacyForm';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.main,
    textUnderlineOffset: `${theme.spacing()}px`,
    marginBottom: theme.spacing(5),
  },
  list: {
    margin: theme.spacing(2),
  },
}));

const OptOutPage = () => {
  const classes = useStyles();

  return (
    <PageWrapper id="opt-out-page">
      <Typography variant="h3" className={classes.header} gutterBottom>
        For California Residents: Opt out of the sale or sharing of personal
        information
      </Typography>
      <Typography variant="body2" gutterBottom>
        Welcome to our portal for submitting California Consumer Privacy Act of
        2018 &#40;as amended by the California Privacy Rights Act of 2020&#44;
        and its implementing regulations&#44; collectively&#44;
        &quot;CCPA&quot;&#41; requests to opt out of the sale and/or sharing of
        your personal information&#46; This form provides a way for individuals
        &#40;like you&#44; or&#44; where required by law&#44; someone you
        authorize&#44; also known as an &lsquo;authorized agent&lsquo;&#41; to
        request us to not sell or share your personal information to third
        parties&#46;
      </Typography>
      <Typography variant="body2" gutterBottom>
        For more information about your CCPA rights and our data practices
        relating to your personal information&#44; please review our{' '}
        <Link
          variant="body2"
          color="secondary"
          href="https://www.sovrn.com/legal/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
        .
      </Typography>

      <Typography variant="body2" gutterBottom>
        We may share your personal information with third parties in ways that
        constitute &quot;sales&quot; under the CCPA&#46; We also
        &quot;share&quot; your personal information when serving targeted
        advertising &#40;referred to as &#39;cross-context behavioral
        advertising&#39; under California law&#41;&#46; You may request that we
        not &quot;sell&quot; or &quot;share&quot; your personal information on a
        going forward basis&#46; Please keep in mind that if you exercise your
        right to opt out of the sale or sharing of your personal
        information&#44; you may still see ads on our publisher&#39;s sites, and
        some data collection will still take place &#40;for example&#44; to
        detect or prevent fraud or deliver the services you request from
        us&#41;&#46;
      </Typography>
      <Typography variant="body2" gutterBottom>
        If your request includes multiple email addresses&#44; you are required
        to submit multiple form requests&#46; If you wish to exercise more than
        one of your CCPA rights&#44; please submit a data rights request through
        our{' '}
        <Link
          variant="body2"
          color="secondary"
          href="https://www.sovrn.com/legal/privacy-center/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Center
        </Link>
        .
      </Typography>

      <Typography variant="body2" gutterBottom>
        When you submit this form&#44; we will take reasonable steps to prevent
        your data to be sold to third parties&#44; or shared for targeted
        advertising&#46; When complete&#44; someone from our Privacy team will
        provide confirmation that your request has been fulfilled&#46;
      </Typography>
      <Typography variant="body2" gutterBottom>
        The information you or your authorized agent provides through the form
        allows us to&#58;
      </Typography>
      <div className={classes.list}>
        <Typography variant="body2">
          1. Understand the nature of our relationship to you&#59;
        </Typography>

        <Typography variant="body2">
          2. Validate your legal rights&#59; and
        </Typography>

        <Typography variant="body2">
          3. Confirm you’re allowed to make the request &#40;in the case of an
          authorized agent&#41;&#46;
        </Typography>
      </div>
      <Typography variant="caption">
        &#42;Note&#58; please review our Privacy Policy for specific
        instructions on using an Authorized Agent&#46;
      </Typography>
      <Typography variant="body2" gutterBottom>
        Sovrn Publishers wishing to inquire about personal data rights should
        contact{' '}
        <Link
          variant="body2"
          color="secondary"
          href="https://knowledge.sovrn.com/kb-tickets/new"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sovrn Support
        </Link>
        &#46; If you need assistance completing the form or submitting your
        request&#44; please contact{' '}
        <Link variant="body2" color="secondary" href="mailto:privacy@sovrn.com">
          privacy@sovrn.com
        </Link>
        .
      </Typography>
      <PrivacyForm form="CCPA DNS" />
    </PageWrapper>
  );
};

export default OptOutPage;
