import React, { ReactNode } from 'react';
import { Snackbar, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SnackbarContent } from '@sovrn/platform-ui-core';

import Header from '../Header';
import { useSnackbarContext } from '../../context/SnackbarContext';

type Props = {
  id: string;
  children: ReactNode;
};
const useStyles = makeStyles((theme: Theme) => ({
  privacyContainer: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6, 12),
    },
  },
  privacyContent: {
    width: 735,
  },
}));

const PageWrapper = ({ children, ...rest }: Props) => {
  const classes = useStyles();
  const { content: snackbar } = useSnackbarContext();

  return (
    <>
      <Header />
      <div className={classes.privacyContainer} id="privacy-center-content">
        <div className={classes.privacyContent} {...rest}>
          {children}
          <Snackbar
            id="privacy-form-snackbar"
            data-cy="privacy-form-snackbar"
            open={snackbar.open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={snackbar.button?.onClick}
            autoHideDuration={4000}
          >
            <SnackbarContent
              id="privacy-form-snackbar-content"
              message={snackbar.message}
              button={snackbar.button}
              error={snackbar.error}
            />
          </Snackbar>
        </div>
      </div>
    </>
  );
};

export default PageWrapper;
