import { UserLocation } from '../../../common';
// This should be updated during VIS-2410 to pass in 2 callbacks to update loading state - one for success and one for failure
export const getCurrentLocation = (
  cb: (position: GeolocationPosition) => void,
  err: (err: GeolocationPositionError) => void,
) => {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(cb, err);
  }
};

export const getLocationData = async (
  coordinates: [number, number],
): Promise<UserLocation> => {
  const latitude = coordinates[0];
  const longitude = coordinates[1];
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&result_type=administrative_area_level_1&key=AIzaSyDiiCFamXuNG9Y6p_tyaPqxpdJc6kMuPOc`,
  );

  if (!response.ok) {
    const errorMsg = 'Failed to retrieve user location';
    console.error(errorMsg);
    return { country: '', state: '' };
  }

  const responseJSON = await response.json();
  const userCountry = responseJSON.results[0].address_components[1].long_name;

  if (userCountry === 'United States') {
    const userState = responseJSON.results[0].address_components[0].long_name;
    return { country: userCountry, state: userState };
  }
  return { country: userCountry };
};
