import React from 'react';
import { ThemeProvider, theme } from '@sovrn/platform-ui-core';
import { StyledEngineProvider, Theme } from '@mui/material';
import { ThemeProvider as ThemeProviderLegacy } from '@mui/styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
type ThemeProvidersProps = {
  children: React.ReactElement;
  seed: string;
};

// This is hacky:
// For some reason the way this frontend is written it is causing issues with the ThemeProvider for UI-Core
// adding the second provider fixes the styling

export const ThemeProviders = ({ children, seed }: ThemeProvidersProps) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider seed={seed}>
        <ThemeProviderLegacy theme={theme}>{children}</ThemeProviderLegacy>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
