import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

const styles = (theme: Theme) =>
  createStyles({
    formContainer: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        width: '67%',
      },
    },
    relationshipContainer: {
      marginTop: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        width: '85%',
      },
    },
    submitButton: {
      width: '100%',
      margin: theme.spacing(7, 0, 1, 0),
      [theme.breakpoints.up('md')]: {
        width: '67%',
      },
    },
    textInput: {
      marginTop: theme.spacing(1),
      minHeight: '48px',
      '& span': {
        color: theme.palette.grey[900],
      },
    },
    radioContainer: {
      marginTop: theme.spacing(1),
    },
    helperText: {
      padding: theme.spacing(0, 0, 1, 4),
      marginTop: theme.spacing(-1),
      lineHeight: '16px',
    },
    privacyLabel: {
      color: theme.palette.grey[900],
    },
    cookieForm: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        width: '67%',
      },
    },
    cookieText: {
      margin: theme.spacing(5, 0, 4, 0),
      [theme.breakpoints.up('md')]: {
        width: '85%',
      },
    },
    placeholderText: {
      '&::placeholder': {
        color: theme.palette.grey[900],
        opacity: 1,
      },
    },
    loading: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },
    checkboxContainer: {
      marginTop: theme.spacing(),
      marginLeft: theme.spacing(8),
    },
    checkboxLabel: {
      ...theme.typography.body2,
    },
  });
export default styles;
