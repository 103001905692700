import React from 'react';
import { Typography, Link, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PageWrapper from '../PageWrapper';
import PrivacyForm from '../PrivacyForm';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.main,
    textUnderlineOffset: `${theme.spacing()}px`,
    marginBottom: theme.spacing(5),
  },
  list: {
    margin: theme.spacing(2),
  },
  bullets: {
    margin: theme.spacing(2, 0),
  },
}));

const DataRightsRequestPage = () => {
  const classes = useStyles();
  return (
    <PageWrapper id="data-right-request">
      <Typography variant="h3" className={classes.header} gutterBottom>
        Privacy request: data rights request
      </Typography>
      <Typography variant="body2" gutterBottom>
        Welcome to our portal for submitting a request to exercise your data
        rights. Depending on the laws where you live, you may have certain
        rights over your personal data such as the right to:
      </Typography>
      <div className={classes.bullets}>
        <Typography variant="body2" gutterBottom>
          &bull; request access to personal data we hold;
        </Typography>
        <Typography variant="body2" gutterBottom>
          &bull; object or restrict processing of personal information, such as
          profiling we perform for the purposes of direct marketing and
          interest-based advertising;
        </Typography>
        <Typography variant="body2" gutterBottom>
          &bull; ask that your personal information be corrected, erased, or
          transferred to another party; and
        </Typography>
        <Typography variant="body2" gutterBottom>
          &bull; where we have asked for consent to process data, consent may be
          withdrawn.
        </Typography>
      </div>
      <Typography variant="body2" gutterBottom>
        For more information about your personal data rights and our data
        practices relating to your personal information, please review our{' '}
        <Link
          variant="body2"
          color="secondary"
          href="https://www.sovrn.com/privacy-policy/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
        .
      </Typography>
      <Typography variant="body2" gutterBottom>
        If your request includes multiple email addresses, you are required to
        submit multiple form requests. For example, if you have an email address
        such as janedoe@gmail.com and janedoe@yahoo.com, a separate form must be
        submitted for each. We will use the email address provided to
        communicate with you regarding your request, including to validate the
        request is authorized by you, where applicable.
      </Typography>
      <Typography variant="body2" gutterBottom>
        Completing this form provides a way for individuals (like you, or, where
        required by law, someone you authorize, also known as an ‘authorized
        agent’) to request access, deletion, correction, or receive a copy of
        the personal data we (or our affiliate companies) may process in the
        course of doing business. If you wish to exercise your right to opt out
        of the sale of your personal data, please visit our opt out portal{' '}
        <Link
          variant="body2"
          color="secondary"
          href="https://privacy.sovrn.com/opt-out"
          target="_blank"
          rel="noopener noreferrer"
        >
          opt out portal{' '}
        </Link>
        .
      </Typography>

      <Typography variant="body2" gutterBottom>
        When you submit this form, someone from our Privacy team will contact
        you for further details and/or instructions. This ensures the request is
        coming from you (or your authorized agent) and fulfills your desired
        action.
      </Typography>
      <Typography variant="body2" gutterBottom>
        The information you or your authorized agent provides through the form
        allows us to:
      </Typography>
      <div className={classes.list}>
        <Typography variant="body2">
          1. Understand the nature of our relationship to you;
        </Typography>

        <Typography variant="body2">2. Validate your legal rights;</Typography>

        <Typography variant="body2">
          3. Confirm you’re allowed to make the request (in the case of an
          authorized agent); and
        </Typography>
        <Typography variant="body2">
          4. Confirm that the data belongs to you.
        </Typography>
      </div>
      <Typography variant="body2" gutterBottom>
        Sovrn Publishers wishing to inquire about personal data rights should
        contact Sovrn Support at{' '}
        <Link
          variant="body2"
          color="secondary"
          href="mailto:publishersupport@sovrn.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          publishersupport[at]sovrn.com
        </Link>
        . If you need assistance completing the form or submitting your request,
        please contact a member of our team by emailing{' '}
        <Link
          variant="body2"
          color="secondary"
          href="mailto:publishersupport@sovrn.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          publishersupport[at]sovrn.com
        </Link>
        , or calling +1 (888) 280-8085. .
      </Typography>
      <PrivacyForm form="DSR" />
    </PageWrapper>
  );
};

export default DataRightsRequestPage;
