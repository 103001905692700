import { PrivacyFormInputs } from './types';

export const getLjtCookie = async () => {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  const include: 'include' = 'include';

  const options = {
    method: 'get',
    credentials: include,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `https://ap.lijit.com/readerinfo/v2?sovrn_retry=true`,
    options,
  );
  const body = await response.json();
  return body;
};
export const getViglinkCookie = async () => {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  const include: 'include' = 'include';

  const options = {
    method: 'get',
    credentials: include,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`https://auth.viglink.com/cookies`, options);
  const body = await response.json();
  return body;
};

export const handleFormSubmit = async ({
  email,
  form,
  country,
  usState,
  sovrnRelationship,
  ljtReader,
  viglink,
  name,
  authorizedAgent,
}: PrivacyFormInputs) => {
  const body = JSON.stringify({
    form,
    country,
    usState: typeof usState === 'undefined' ? null : usState,
    sovrnRelationship,
    email,
    ljtReader: typeof ljtReader === 'undefined' ? null : ljtReader,
    viglink: typeof viglink === 'undefined' ? null : viglink,
    name: typeof name === 'undefined' ? null : name,
    authorizedAgent,
  });
  const options = {
    method: 'post',
    headers: {
      'Content-Type': 'text/plain',
    },
    body,
  };
  const url = `${window.location.origin}/sendEmail`;

  return await fetch(url, options);
};
