import { LocationDatum } from './locations';

export const locationsMap = (locationsArr: LocationDatum[]) => {
  return locationsArr.map((location: LocationDatum) => location.label);
};

export const checkLocation = (locationLabels: string[], location: string) => {
  const filterLocations = locationLabels.filter(
    (locationLabel) => locationLabel === location,
  );
  return filterLocations[0];
};
