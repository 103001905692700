import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  TextField,
  Radio,
  CircularProgress,
  Checkbox,
} from '@sovrn/platform-ui-core';
import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useSnackbarContext } from '../../context/SnackbarContext';
import styles from './styles';
import CookieForm from './CookieForm';
import {
  checkLocation,
  countries as countriesArray,
  locationsMap,
  usStatesAndTerritories as usArray,
  getLjtCookie,
  PrivacyFormInputs,
  handleFormSubmit,
  getViglinkCookie,
} from '../../common';
import { getCurrentLocation, getLocationData } from './GeoLocation';

const schema = yup.object().shape({
  country: yup.string().required('Country is required'),
  email: yup.string().email().required(),
  usState: yup.string().when('country', {
    is: 'United States',
    then: yup.string().label('Select your State').required(),
  }),
  sovrnRelationship: yup.string(),
  name: yup.string().when('sovrnRelationship', {
    is: (value: string) => value !== 'reader',
    then: yup.string().required(),
  }),
  authorizedAgent: yup.boolean(),
});

type Props = {
  form: string;
};

const useStyles = makeStyles(styles, { name: 'PrivacyForm' });

const PrivacyForm = ({ form }: Props) => {
  const classes = useStyles();
  const countries = locationsMap(countriesArray);
  const usStatesAndTerritories = locationsMap(usArray);
  const { setContent: setSnackbar } = useSnackbarContext();

  const [ljtReaderId, setLjtReaderId] = useState<string | undefined>(undefined);
  const [viglink, setViglink] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitSuccessful },
    watch,
    clearErrors,
    reset,
    setValue,
    getValues,
  } = useForm<PrivacyFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldFocusError: true,
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        email: '',
        sovrnRelationship: 'reader',
        name: '',
      });
      setValue('sovrnRelationship', 'reader');
      setLjtReaderId('');
      setViglink('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmit = async (data: PrivacyFormInputs) => {
    data.ljtReader = ljtReaderId;
    data.viglink = viglink;
    data.form = form;
    try {
      setIsSubmitting(true);
      await handleFormSubmit(data);
      setSnackbar({
        open: true,
        message: 'Your request was submitted successfully.',
        button: {
          title: 'Dismiss',
          onClick: () => setSnackbar((sb) => ({ ...sb, open: false })),
        },
      });
      setLjtReaderId(undefined);
      setViglink(undefined);
    } catch (e) {
      setSnackbar({
        open: true,
        error: true,
        message: 'There was a problem trying to submit your request.',
        button: {
          title: 'Dismiss',
          onClick: () => setSnackbar((sb) => ({ ...sb, open: false })),
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLjtReader = async () => {
    try {
      const data = await getLjtCookie();
      setLjtReaderId(data.ljt_reader);
    } catch (e) {
      setLjtReaderId('No Cookie Found');
    }
  };

  const handleViglinkId = async () => {
    const { results } = await getViglinkCookie();
    setViglink(
      results['vglnk.Agent.p'] === ''
        ? 'No Cookie Found'
        : results['vglnk.Agent.p'],
    );
  };

  useEffect(() => {
    getCurrentLocation(
      async (position: GeolocationPosition) => {
        const coordinates: [number, number] = [
          position.coords.latitude,
          position.coords.longitude,
        ];
        const userLocation = await getLocationData(coordinates);

        const currentUserCountry = checkLocation(
          countries,
          userLocation.country,
        );
        setValue('country', currentUserCountry);

        if (userLocation.state) {
          const currentUserState = checkLocation(
            usStatesAndTerritories,
            userLocation.state,
          );
          setValue('usState', currentUserState);
        }
        setValue('sovrnRelationship', 'reader');
        setIsLoading(false);
      },
      (err: GeolocationPositionError) => {
        setIsLoading(false);
        console.error(err);
      },
    );
    setValue('sovrnRelationship', 'reader');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const watchSovrnRelationship = watch('sovrnRelationship', 'reader');
  const watchCountry = watch('country');
  const sovrnRelationshipLabel = () => (
    <>
      <Typography variant="body2">
        I am acting as an Authorized Agent.
      </Typography>
      <Typography
        variant="caption"
        component="div"
        sx={{ fontSize: '12px', color: 'rgba(40, 40, 40, 0.6)' }}
      >
        Must submit the email address and/or cookie(s) of the person on whom’s
        behalf you are acting
      </Typography>
    </>
  );
  return (
    <>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress id="privacy-form-loading" color="secondary" />
        </div>
      ) : (
        <>
          <div id="privacy-center-form" className={classes.formContainer}>
            <Controller
              name="country"
              control={control}
              render={() => (
                <Autocomplete
                  id="country"
                  data-cy="country"
                  className={classes.textInput}
                  options={countries}
                  TextFieldProps={{
                    label: 'Select your location',
                    helperText: errors.country
                      ? errors.country.message
                      : 'Select the country of your residency',
                    error: !!errors.country,
                    required: true,
                  }}
                  onClose={() => clearErrors('country')}
                  onChange={(_, value: string) => {
                    value
                      ? reset({
                          country: value,
                          usState: '',
                          sovrnRelationship: getValues('sovrnRelationship'),
                          name: getValues('name'),
                          email: getValues('email'),
                        })
                      : null;
                  }}
                  value={getValues('country') || null}
                />
              )}
            />

            {watchCountry === 'United States' && (
              <Controller
                name="usState"
                control={control}
                render={() => (
                  <Autocomplete
                    id="us-state"
                    data-cy="us-state"
                    options={usStatesAndTerritories}
                    className={classes.textInput}
                    TextFieldProps={{
                      label: 'Select your State',
                      helperText: errors.usState
                        ? errors.usState.message
                        : 'Select the State of your residency',
                      required: true,
                      error: !!errors.usState,
                    }}
                    onClose={() => clearErrors('usState')}
                    onChange={(_, value: string) => {
                      value
                        ? reset({
                            country: getValues('country'),
                            usState: value,
                            sovrnRelationship: getValues('sovrnRelationship'),
                            name: getValues('name'),
                            email: getValues('email'),
                          })
                        : null;
                    }}
                    value={getValues('usState') || null}
                  />
                )}
              />
            )}

            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, ref } }) => (
                <TextField
                  required
                  id="email"
                  data-cy="email"
                  label="Enter your email address"
                  fullWidth
                  helperText={
                    errors.email
                      ? 'must be a valid email address'
                      : 'You must submit a separate form for each email address'
                  }
                  error={!!errors.email}
                  name="email"
                  onChange={onChange}
                  inputRef={ref}
                  inputProps={{
                    autoComplete: 'off', // disable autocomplete and autofill
                  }}
                  className={classes.textInput}
                  value={getValues('email') || ''}
                />
              )}
            />

            <div
              id="relationship-form"
              className={classes.relationshipContainer}
            >
              <FormLabel className={classes.privacyLabel}>
                Your relationship with Sovrn.
              </FormLabel>

              <Controller
                name="sovrnRelationship"
                control={control}
                defaultValue="reader"
                render={({ field: { onChange } }) => (
                  <RadioGroup
                    className={classes.radioContainer}
                    defaultValue="reader"
                    onChange={onChange}
                    value={getValues('sovrnRelationship') || 'reader'}
                  >
                    <FormControlLabel
                      id="reader-radio"
                      data-cy="reader"
                      value="reader"
                      control={<Radio color="primary" size="medium" />}
                      label="Reader"
                    />
                    <FormHelperText className={classes.helperText}>
                      Person whose information we collect when the person visits
                      Sovrn customer websites; a Sovrn customer is the site
                      publisher.
                    </FormHelperText>
                    {getValues('sovrnRelationship') === 'reader' && (
                      <div className={classes.checkboxContainer}>
                        <Controller
                          name="authorizedAgent"
                          control={control}
                          defaultValue={false}
                          render={({ field: { onChange } }) => (
                            <FormControlLabel
                              value="authorizedAgent"
                              control={
                                <Checkbox disableRipple onChange={onChange} />
                              }
                              label={sovrnRelationshipLabel()}
                              labelPlacement="end"
                              classes={{ label: classes.checkboxLabel }}
                            />
                          )}
                        />
                      </div>
                    )}
                    <FormControlLabel
                      id="staff-radio"
                      data-cy="staff"
                      value="staff"
                      control={<Radio color="primary" size="medium" />}
                      label="Staff"
                    />
                    <FormHelperText className={classes.helperText}>
                      Employees and contractors of Sovrn.
                    </FormHelperText>
                    <FormControlLabel
                      id="candidate-radio"
                      data-cy="candidate"
                      value="candidate"
                      control={<Radio color="primary" size="medium" />}
                      label="Staff Candidate"
                    />
                    <FormHelperText className={classes.helperText}>
                      Individuals interested in a Sovrn career, and have
                      submitted personal information through our Career site.
                    </FormHelperText>
                  </RadioGroup>
                )}
              />

              {watchSovrnRelationship !== 'reader' && (
                <Controller
                  render={({ field: { onChange, ref } }) => (
                    <TextField
                      value={getValues('name') || ''}
                      className={classes.textInput}
                      id="name"
                      data-cy="name"
                      label="Full name"
                      fullWidth
                      helperText={
                        errors.name
                          ? 'must be a valid name'
                          : 'You must submit a name when Staff or Staff candidate'
                      }
                      required={true}
                      error={!!errors.name}
                      onChange={onChange}
                      inputRef={ref}
                      inputProps={{
                        autoComplete: 'disabled', // disable autocomplete and autofill
                      }}
                    />
                  )}
                  name="name"
                  control={control}
                />
              )}
            </div>
          </div>
          <CookieForm
            handleLjtReader={handleLjtReader}
            handleViglinkId={handleViglinkId}
            ljtReaderId={ljtReaderId}
            setLjtReaderId={setLjtReaderId}
            setViglink={setViglink}
            viglink={viglink}
          />
          <Button
            id="submit"
            data-cy="submit"
            onClick={handleSubmit(onSubmit)}
            color="secondary"
            variant="contained"
            className={classes.submitButton}
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress color="secondary" size={20} />
            ) : (
              'SUBMIT REQUEST'
            )}
          </Button>
        </>
      )}
    </>
  );
};

export default PrivacyForm;
