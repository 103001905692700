import React, { useContext } from 'react';

import { SnackbarContext as SnackbarContextData } from '../../common/types';

const SnackbarContext = React.createContext<SnackbarContextData | undefined>(
  undefined,
);

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error(
      'useSnackbarContext must be used within a SnackbarContextProvider',
    );
  }
  return context;
};

export default SnackbarContext;
