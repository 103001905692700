import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import OptOutPage from '../OptOutPage';
import DataRightsRequestPage from '../DataRightsRequestPage';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    minHeight: '100vh',
  },
}));
const PrivacyRoutes = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Switch>
        <Route path="/opt-out" component={OptOutPage} />
        <Route path="/data-rights-request" component={DataRightsRequestPage} />
        <Route path="/" exact>
          <Redirect to="/opt-out" />
        </Route>
      </Switch>
    </div>
  );
};

export default PrivacyRoutes;
