import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Typography } from '@mui/material';

import PrivacyRoutes from './routes';
import { SnackbarContextProvider } from '../../context/SnackbarContext';
import { ThemeProviders } from './ThemeProviders';

type Props = {
  envHostname: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const App = (props: Props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Some loading logic here
    setLoading(false);
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <ThemeProviders seed="privacy-center">
      <SnackbarContextProvider>
        <Router basename="/">
          <PrivacyRoutes />
        </Router>
      </SnackbarContextProvider>
    </ThemeProviders>
  );
};

export default App;
