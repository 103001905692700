import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Divider, SovrnLogo as Logo } from '@sovrn/platform-ui-core';

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    padding: theme.spacing(2, 0, 2, 12),
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <div id="privacy-center-header">
      <div className={classes.logo}>
        <Logo />
      </div>
      <Divider />
    </div>
  );
};

export default Header;
