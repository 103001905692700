import React from 'react';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Button, TextField } from '@sovrn/platform-ui-core';

type Props = {
  handleLjtReader: () => void;
  handleViglinkId: () => void;
  ljtReaderId?: string;
  setLjtReaderId: (value: string) => void;
  viglink?: string;
  setViglink: (value: string) => void;
  classes?: Record<any, string>;
};

import styles from './styles';

const CookieForm = ({
  handleLjtReader,
  handleViglinkId,
  ljtReaderId,
  setLjtReaderId,
  viglink,
  setViglink,
  classes = {},
}: Props) => {
  const handleLjtReaderInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLjtReaderId(event.target.value);
  };

  const handleViglinkInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setViglink(event.target.value);
  };

  return (
    <>
      <div id="cookie-form" className={classes.cookieForm}>
        <Typography variant="body2" className={classes.cookieText}>
          We operate on two different cookie identifiers. Providing your cookies
          helps us identify the data we may hold, but is not required to submit
          your request through this form. Please either use our automated cookie
          collection or enter your cookie in the fields below.
        </Typography>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleLjtReader}
          id="get-ljt-button"
          data-cy="get-ljt-button"
        >
          Identify Sovrn cookies
        </Button>
        <TextField
          onChange={handleLjtReaderInputChange}
          InputProps={{ classes: { input: classes.placeholderText } }}
          name="ljtReaderId"
          classes={{ root: classes.textInput }}
          placeholder="Your Sovrn ID (also called ljt_readerID)"
          fullWidth
          value={ljtReaderId}
          id="ljtReaderId"
          data-cy="ljtReaderId"
        />
        <Typography variant="caption" color="textSecondary">
          This is auto-filled when you press &lsquo;Identify Sovrn
          Cookies&rsquo; above but you can also manually complete this field
        </Typography>
      </div>

      <div className={classes.cookieForm}>
        <Button
          color="secondary"
          variant="outlined"
          id="get-viglink-button"
          data-cy="get-viglink-button"
          onClick={handleViglinkId}
        >
          Identify //Commerce cookies
        </Button>
        <TextField
          onChange={handleViglinkInputChange}
          InputProps={{ classes: { input: classes.placeholderText } }}
          classes={{ root: classes.textInput }}
          placeholder="Your //Commerce ID (also called viglink.agent.p)"
          fullWidth
          value={viglink}
          id="viglinkId"
          data-cy="viglinkId"
          name="viglinkId"
        />
        <Typography variant="caption" color="textSecondary">
          This is auto-filled when you press &lsquo;Identify //Commerce
          cookies&rsquo; above
        </Typography>
      </div>
    </>
  );
};

export default withStyles(styles, { name: 'CookieForm' })(CookieForm);
